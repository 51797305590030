<template>
  <div class="top">
    <div class="image-wrap">
      <el-image :src="logo" fit="fill" />
    </div>
    <el-input v-model="search" class="search" placeholder="输入内容搜索" @keyup.enter.native="handleQuery">
      <template slot="append">
        <el-button type="primary" @click="handleQuery">搜索</el-button>
      </template>
    </el-input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TopLogo',

  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters(['logo'])
  },
  methods: {
    handleQuery() {
      if (this.search) {
        window.open('/article/search/' + this.search)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  .image-wrap{
  }
}

@media screen and (min-width: 960px) {
  .top {
    width: 960px;
    .image-wrap {
      flex: 7;
      padding: 30px 40px 30px 0

    }
    .search {
      flex: 3;
    }
  }
}
@media screen and (max-width: 960px) {
  .top {
    .image-wrap {
      flex: 1;
      padding: 20px
    }
    .search {
      display: none;
    }
  }
}
</style>
